<template>
	<div class="tasklist-bidder">
		<div class="tasklist-bidder-top">
			<div class="person-header">
				<!--等待审核-->
				<p v-if="task.audit_status == 1">
					<i class="iconfont icon-wait y"></i>
					<strong class="yellowp">等待审核</strong>
					<span class="f-12 h-57">平台将在1个工作日内审核。</span>
				</p>

				<!--审核未通过-->
				<p v-if="task.audit_status == 2">
					<i class="iconfont icon-delete r wh-16"></i>
					<strong class="redp">审核未通过</strong>
					<span class="f-12 h-57" v-if="task.audit_info">原因：{{ task.audit_info.content }}</span>
				</p>

				<!--任务流程 未开标-->
				<p v-if="task.audit_status == 9 && task.status == 1">
					<i class="iconfont icon-wait g"></i>
					<strong class="greenp">等待开标</strong>
					<span class="f-12 h-57">开标时间：{{ task.bid_start_time }}</span>
				</p>

				<!--任务流程 该标已关闭-->
				<p v-if="task.audit_status == 9 && task.status == 2">
					<i class="iconfont icon-wait r"></i>
					<strong class="redp">已关闭</strong>
					<span class="f-12 h-57">项目长期未响应，已经关闭</span>
				</p>

				<!--任务流程 竞标中-->
				<p v-if="task.audit_status == 9 && task.status == 3">
					<i class="iconfont icon-success g wh-16"></i>
					<strong class="greenp f-12 h-57">竞标中</strong>
					<span class="f-12 h-57">{{ task.bid_start_time }} - {{ task.bid_end_time }}</span>
					<span class="f-12 h-57">
						<i class="iconfont icon-staff b"></i>
						&nbsp;&nbsp;{{ task.bid_count }}人投标
					</span>
					<span class="f-12 h-57">
						<i class="iconfont icon-pass b"></i>
						&nbsp;&nbsp;已选{{ task.bid_ok_count }}人
					</span>
				</p>

				<!--任务流程 流标-->
				<p v-if="task.audit_status == 9 && task.status == 4">
					<i class="iconfont icon-delete r"></i>
					<strong class="redp">流标</strong>
					<span class="f-12 h-57">无人投标，该项目流标</span>
				</p>

				<!--任务流程 选标期-->
				<p v-if="task.audit_status == 9 && task.status == 5">
					<i class="iconfont icon-time g"></i>
					<strong class="g f-12 h-57">选标期</strong>
					<span class="f-12 h-57">截至{{ task.bid_select_end_time }}</span>
				</p>

				<!--任务流程 资金准备期-->
				<p v-if="task.audit_status == 9 && task.status == 7">
					<i class="iconfont icon-wait y"></i>
					<strong class="yellowp">资金准备期</strong>
					<span class="f-12 h-57" v-show="task.plan_period_count > 1">共{{ task.plan_period_count }}期</span>
					<span class="f-12 redbp">请尽快托管资金</span>
				</p>

				<!--工作中-->
				<p v-if="task.audit_status == 9 && task.status == 8">
					<i class="iconfont icon-wait g"></i>
					<strong class="greenp">工作中</strong>

					<!--如果是分期的-->
					<span v-if="task.plan_period_count > 1" class="f-12 h-57">共{{ task.plan_period_count }}个工作周期</span>

				</p>

        <p v-if="task.status == 9">
          <i class="iconfont icon-success g"></i>
          <strong class="greenp">已完成</strong>
        </p>

				<div class="person-header-right">
					<el-dropdown trigger="hover">
						<span class="el-dropdown-link">更多操作</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item v-show="task.audit_status != 9 && task.certification_id > 0">
								<el-button type="text" icon="el-icon-video-pause" @click="jumpToCertification(task.sn)">更换认证主体</el-button>
							</el-dropdown-item>
							<!-- <el-dropdown-item v-show="task.audit_status != 9">资金托管方式</el-dropdown-item> -->
							<!--<el-dropdown-item v-show="task.audit_status == 9 && task.status < 8 && task.status != 2">关闭</el-dropdown-item>-->

							<!--未开标 可提前开标-->
							<el-dropdown-item v-show="task.status == 1 && task.audit_status == 9">
								<el-button type="text" icon="el-icon-video-pause" @click="startBid(task)">提前开标</el-button>
							</el-dropdown-item>

							<!--竞标阶段 可提前结束竞标-->
							<el-dropdown-item v-show="task.status == 3 && task.audit_status == 9">
								<el-button type="text" icon="el-icon-video-pause" @click="endBid(task)">结束招标</el-button>
							</el-dropdown-item>

							<!--选标阶段 可提前结束选标-->
							<el-dropdown-item v-show="task.status == 5 && task.audit_status == 9">
								<el-button type="text" icon="el-icon-video-pause" @click="endSelectBid(task)">结束选标</el-button>
							</el-dropdown-item>

							<!--资金托管期-->
							<!-- <el-dropdown-item v-show="task.audit_status == 9 && task.status == 7">资金托管</el-dropdown-item> -->

							<el-dropdown-item v-show="task.status == 1 || task.status == 2 || task.status == 4">
								<el-button type="text" icon="el-icon-delete" @click="deleteOneTask(task)">删除</el-button>
							</el-dropdown-item>

              <el-dropdown-item >
                <el-button type="text" icon="el-icon-back" @click="jumpToBack()">返回列表</el-button>
              </el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
			<div class="person-bottom">
				<div class="person-bottom-header flex-sb">
					<div class="person-bottom-header-left">
						<i v-if="task.certification_id == 0" class="iconfont icon-tips r wh-16"></i>
						<span v-if="task.certification_id == 0" class="redp">请为该项目设置主体</span>

						<!--个人认证-->
						<i v-if="task.certification_id > 0 && task.certification_type == 1" class="iconfont icon-personal wh-16 b"></i>
						<span v-if="task.certification_id > 0 && task.certification_type == 1" class="f-16 h-57">{{ task.certificaion_info.cert_name }}</span>
						<!--end 个人认证-->

						<!--企业认证-->
						<i v-if="task.certification_id > 0 && task.certification_type == 2" class="iconfont icon-hirer wh-16 b"></i>
						<span v-if="task.certification_id > 0 && task.certification_type == 2" class="f-16 h-57">{{ task.certificaion_info.cert_name }}</span>
						<!--end 企业认证-->
					</div>
					<div class="person-bottom-header-right">
						<span class="h-57 f-12">项目编号：{{ task.sn }}</span>
					</div>
				</div>
				<div class="person-bottom-center">
					<p>{{ task.subject }}</p>
					<div class="person-bottom-center-font" v-show="showContent">
						<p v-show="show">
							{{ task.content }}
							<button @click="showButt">展开</button>
						</p>
						<p v-show="hide">
							{{ task.content }}
							<button @click="hideButt">收起</button>
						</p>
						<ul>
							<li>
								<el-button type="text">
									<i class="iconfont icon-pay wh-16 b"></i>
									预算{{ task.budget_amount }}
								</el-button>
							</li>
							<li>
								<el-button type="text">
									<i class="iconfont icon-website b wh-16"></i>
									{{ task.pay_channel_name }}
								</el-button>
							</li>

							<!--固定薪酬-->
							<li v-if="task.pay_way == 1">
								<el-button type="text">
									<i class="iconfont icon-definite b wh-16"></i>
									{{ task.pay_way_name }}
								</el-button>
							</li>
							<!--时薪-->
							<li v-if="task.pay_way == 2">
								<el-button type="text">
									<i class="iconfont icon-hour b wh-16"></i>
									{{ task.pay_way_name }}
								</el-button>
							</li>

							<!--支付周期 周、月、一次性、分期-->
							<li v-if="task.pay_cycle == 1">
								<el-button type="text">
									<i class="iconfont icon-week b wh-16"></i>
									{{ task.pay_cycle_name }}
								</el-button>
							</li>
							<li v-if="task.pay_cycle == 2">
								<el-button type="text">
									<i class="iconfont icon-month b wh-16"></i>
									{{ task.pay_cycle_name }}
								</el-button>
							</li>
							<li v-if="task.pay_cycle == 3">
								<el-button type="text">
									<i class="iconfont icon-whole b wh-16"></i>
									{{ task.pay_cycle_name }}
								</el-button>
							</li>
							<li v-if="task.pay_cycle == 4">
								<el-button type="text">
									<i class="iconfont icon-installment b wh-16"></i>
									{{ task.pay_cycle_name }}
								</el-button>
							</li>

							<li>
								<el-button type="text">
									<i class="iconfont icon-personal b wh-16"></i>
									雇佣 {{ task.plan_hire_count }} 人
								</el-button>
							</li>
							<li v-show="false">
								<el-button type="text">
									<i class="iconfont icon-time b wh-16"></i>
									立即发布
								</el-button>
							</li>

							<li v-if="task.work_location == 2">
								<el-button type="text">
									<i class="iconfont icon-address b wh-16"></i>
									工作地点 {{ task.work_region_name }}
								</el-button>
							</li>

							<li v-show="task.audit_status == 1">
								<el-button type="text">
									<i class="iconfont icon-time b wh-16"></i>
									提交时间 {{ task.created_at }}
								</el-button>
							</li>
						</ul>

						<ol>
							<strong>所需技能：</strong>
							<li v-for="(item, key) in task.skills" :key="key">{{ item.name }}</li>
						</ol>

						<div class="person-bottom-img flex" v-if="attrCount > 0">
							<div class="person-bottom-img-left"><strong class="f-14">相关附件：</strong></div>
							<div class="person-bottom-img-right">
								<dl>
									<li v-for="(attr, index) in task.attrs" :key="index">
										<i class="iconfont icon-file-DOC wh-12 b"></i>
										<span>{{ attr.file_name }}</span>
									</li>
								</dl>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="tasklist-bidder-bottom" ref="svgtr">
			<!-- <i class="iconfont icon-arrow-"></i> -->
			<svg class="icon arrow-large-down" :class="{ down: isActive, up: hasError }" aria-hidden="true" @click="changeShow">
				<use xlink:href="#icon-arrow-large-down"></use>
			</svg>
			<!-- <button @click="changeShow">{{ showOrHide }}</button> -->
		</div>
	</div>
</template>

<script>
import { loadTaskListOfHirer, deleteTask, endTaskBid, endTaskSelectBid, startTaskBid } from '@/api/taskuc.js';
import {get} from 'lodash-es'
import urlencode from 'urlencode';
export default {
	name: 'InfoHeader',
	data() {
		return {
			isShow: true, //显示展开与收起
			showContent: false, //显示中间
			show: true, //显示
			hide: false, //隐藏
			isActive: true,
			hasError: false,
      get
		};
	},
	props: {
		task: {
			type: Object,
			default: () => {}
		}
	},
	methods: {
		deleteOneTask(task) {
			let _this = this;
			this.$confirm('您确定删除编号为【' + task.sn + '】的任务吗？删除后任务无法找回，请慎重处理')
				.then(_ => {
					deleteTask(task.id, _this.$root.current_token).then(res => {
						_this.$emit('reload')
					});
				})
				.catch(_ => {});
		},
		startBid(task) {
			let _this = this;
			this.$confirm('您确定编号为【' + task.sn + '】的任务提前开标吗？')
				.then(_ => {
					startTaskBid(task.id, _this.$root.current_token).then(res => {
						_this.$emit('reload')
					});
				})
				.catch(_ => {});
		},
		endBid(task) {
			let _this = this;
			this.$confirm('您确定提前结束编号为【' + task.sn + '】的招标吗？结束后，自由职业者将无法参与竞标。请慎重处理')
				.then(_ => {
					endTaskBid(task.id, _this.$root.current_token).then(res => {
						_this.$emit('reload')
					});
				})
				.catch(_ => {});
		},
		endSelectBid(task) {
			let _this = this;
			this.$confirm('您确定提前结束编号为【' + task.sn + '】的选标吗？选标结束后,任务将进入工作模式！')
				.then(_ => {
					endTaskSelectBid(task.id, _this.$root.current_token).then(res => {
						_this.$emit('reload')
					});
				})
				.catch(_ => {});
		},

		jumpToFront(task) {
			window.open('/task/info/' + task.sn, '_blank');
		},
		jumptToUpdate(task) {
			this.$router.push('/task/update/' + task.sn + '?redirect_url=' + urlencode('/home/hirer/task/list'));
		},
		jumpToCertification(taskSN) {
			//跳转到认证界面
			this.$router.push('/auth/task/' + taskSN + '?redirect_url=' + urlencode('/home/hirer/task/list'));
		},
		jumpToWorkstation(taskSN) {
			//跳转到工作管理界面
			this.$router.push('/home/hirer/task/info?sn=' + taskSN);
		},
    jumpToBack(){
      this.$router.push('/home/hirer/task/list');
    },
		changeShow() {
			this.isShow = !this.isShow;
			this.showContent = !this.showContent;
			this.hasError = !this.hasError;
		},
		showButt() {
			this.show = false;
			this.hide = true;
		},
		hideButt() {
			this.show = true;
			this.hide = false;
		}
	},
	computed: {
		showOrHide() {
			return this.isShow ? '展开' : '收起';
		},
    attrCount: function() {
      return get(this.task, 'attrs.length', 0)
    }
	}
};
</script>

<style lang="less" scoped>
.tasklist-bidder {
	background: #fff;
	.tasklist-bidder-top {
		width: 940px;
		.person-header {
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid #b5b5b5;
			p {
				height: 18px;
				display: flex;
				align-items: center;
				img {
					margin-left: 20px;
				}
				big {
					margin-left: 10px;
				}
				span {
					margin-left: 20px;
				}
			}
			/deep/.el-dropdown {
				margin-top: 40px;
				margin-right: 40px;
			}
		}
		.person-bottom {
			width: 860px;
			margin: 0 auto;
			.person-bottom-header {
				margin-top: 40px;
				.person-bottom-header-left {
					span {
						font-size: 14px;
						color: #575757;
						margin-left: 10px;
					}
				}
			}
			.person-bottom-center {
				margin-top: 20px;
				p {
					font-size: 18px;
					width: 840px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.person-bottom-center-font {
					p {
						font-size: 12px;
						color: #575757;
						margin-top: 20px;
						button {
							background-color: transparent;
							color: #00a2e6;
						}
					}
					ul {
						display: flex;
						flex-wrap: wrap;
						height: 42px;
						align-items: center;
						margin-top: 20px;
						text-align: center;
						li {
							height: 16px;
							margin-bottom: 10px;
							/deep/.el-button {
								height: 16px;
								padding: 0;
							}
							/deep/.el-button--text {
								color: #575757;
								margin-left: 10px;
								text-align: center;
							}
						}
					}
					ol {
						height: 24px;
						margin-top: 20px;
						display: flex;
						align-items: center;
						al strong {
							height: 24px;
							font-size: 14px;
							text-align: center;
						}
						li {
							color: #fff;
							background-color: #00a2e6;
							padding: 7px 13px;
							border-radius: 12px;
							margin-left: 10px;
							font-size: 12px;
						}
					}
					.person-bottom-img {
						margin-top: 20px;
						dl {
							li {
								margin-bottom: 10px;
								span {
									margin-left: 10px;
									font-size: 12px;
									color: #575757;
								}
							}
						}
					}
				}
			}
		}
	}
	.tasklist-bidder-bottom {
		width: 160px;
		margin: 0 auto;
		margin-top: 30px;
		padding-bottom: 20px;
		button {
			width: 160px;
			height: 24px;
			background-color: #00a2e6;
		}
	}
}
/deep/.el-dropdown-link {
	cursor: pointer;
	color: #00a2e6;
}
.arrow-large-down {
	width: 60px;
	height: 10px;
	cursor: pointer;
}
.down {
}
.up {
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}
</style>
